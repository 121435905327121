import React from 'react';

const Anchor = ({ id = '#', children, offsetTop = 120 }) => (
  <>
    <a
      id={id}
      css={`
        display: block;
        position: relative;
        top: -${offsetTop}px;
        visibility: hidden;
      `}
    ></a>
    {children}
  </>
);

export default Anchor;
