import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

import Link from './Link';

const Wrapper = styled(Flex)`
  width: ${({ width }) => width || 340}px;
  height: ${({ height }) => height || 80}px;
  margin: ${({ margin }) => margin || 'inherit'};
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px #707070;
  background-color: #ffffff;
  cursor: pointer;
`;

const Text = styled.span`
  font-size: ${({ size }) => size}px;
  color: #3b4043;
`;

const BasicButton = ({ title, onClick, width, height, fontSize, margin }) => (
  <Wrapper center onClick={onClick} width={width} height={height} margin={margin}>
    <Text size={fontSize}>{title}</Text>
  </Wrapper>
);

const Button = ({ title, link, onClick, width, height, fontSize, margin, external }) => {
  if (link) {
    return (
      <Link to={link} onClick={onClick} external={external}>
        <BasicButton
          title={title}
          width={width}
          height={height}
          fontSize={fontSize}
          margin={margin}
        />
      </Link>
    );
  }
  if (!link && onClick) {
    return (
      <BasicButton
        onClick={onClick}
        title={title}
        width={width}
        height={height}
        fontSize={fontSize}
        margin={margin}
      />
    );
  }
  return (
    <BasicButton title={title} width={width} height={height} fontSize={fontSize} margin={margin} />
  );
};

export default Button;
