import React from 'react';
import Flex from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ListItemBig = ({
  icon = faCheckCircle,
  iconStyle = { fontSize: 18, marginRight: 10, marginTop: 4 },
  iconColor = '#000',
  label,
  labelSize,
}) => (
  <Flex>
    <Flex column>
      <FontAwesomeIcon icon={icon} style={iconStyle} color={iconColor} />
    </Flex>
    <Flex center>
      <span
        css={`
          font-size: ${labelSize || 18}px;
        `}
      >
        {label}
      </span>
    </Flex>
  </Flex>
);

export default ListItemBig;
