import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import '@openfonts/m-plus-rounded-1c_japanese';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { A } from '../components/common/Link.style';
import { Img } from '../components/common/Img.style';
import { H3, H4 } from '../components/common/H.style';
import Link from '../components/Link';
import Card from '../components/Card';
import PcrMenu from '../components/PcrMenu';
import ListItem from '../components/ListItem';
import ListItemBig from '../components/ListItemBig';
import { ContentWrapper } from '../components/common/ContentWrapper.style';
import ButtonList from '../components/ButtonList';
import Anchor from '../components/Anchor';

import pcr from '../assets/img/pcr.jpg';
import card from '../assets/img/card.png';


const H4pWrapper = styled(Flex)`
  margin: 2em 0;
  border-radius: 25px;
  background-color: #5A91DB;
  box-sizing: border-box;
`;

const StyledH4p = styled.h4`
  margin: .25em 1.5em;
  font-size: ${({ fontSize }) => fontSize}px;
  color: #ffffff;
  font-weight: normal;
  font-family:"M PLUS Rounded 1c",TsukuARdGothic-Regular;
`;

export const H4p = ({ children, size }) => (
  <H4pWrapper alignCenter>
    <StyledH4p fontSize={(size === 'sm' && 12) || 18}>{children}</StyledH4p>
  </H4pWrapper>
);

const H4iWrapper = styled(Flex)`
  margin: 2em 0;
  border-radius: 25px;
  background-color: #DB5A5A;
  box-sizing: border-box;
`;

export const H4i = ({ children, size }) => (
  <H4iWrapper alignCenter>
    <StyledH4p fontSize={(size === 'sm' && 12) || 18}>{children}</StyledH4p>
  </H4iWrapper>
);

const H4cWrapper = styled(Flex)`
margin: 2em 0;
border-radius: 25px;
background-color: #F0862E;
box-sizing: border-box;
text-align: center;
`;

export const H4c = ({ children, size }) => (
  <H4cWrapper alignCenter>
    <StyledH4p fontSize={(size === 'sm' && 12) || 18}>{children}</StyledH4p>
  </H4cWrapper>
);

const PcrButton = styled(Flex)`
  height: 40px;
  max-width: 450px;
  margin: 1em auto;
  padding: .5em 2em;
  border-radius: 6px;
  border: solid 4px #5A91DB;
  background-color: #5A91DB;
  box-sizing: border-box;
`;


const WhiteText = styled.div`
  font-size: 18px;
  color: #ffffff;
`;

const Span = styled.span`
font-size: 0.8em;
`;

const Exams = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="新型コロナ関連検査">
      <Img src={pcr} />
      
      <div>
        <PcrMenu size={size} />
      </div>
     
      <ContentWrapper hasMarginBottom hasMarginTop>
        <Anchor id="about"></Anchor>
        <H3>当院のPCR検査について</H3>
        <P>
          <ListItemBig
            iconColor="#f0862e"
            label="出国前RT-PCR検査 + 陰性証明書（和英併記文）¥15,000(税込)最短当日16時発行"
          />
          <ListItemBig
            iconColor="#f0862e"
            label="出国前NEAR法検査 + 陰性証明書（和英併記文）¥15,000(税込)最短当日13時発行"
          />
              ※NEAR法の場合、お急ぎ発行サービス(¥3,000)で1時間で発行も可能です。<br/>
          <ListItemBig
            iconColor="#f0862e"
            label="出国前定性抗原検査（ART）+ 陰性証明書（和英併記文）¥10,000(税込) 4時間後発行"
          />
              ※渡航先によって抗原検査は適用できない場合がございます。<br/>
              ※ARTの場合、お急ぎ発行サービス(¥3,000)で1時間で発行も可能です。<br/>
          <ListItemBig
            iconColor="#f0862e"
            label="入国後・帰国後検査 + 陰性（緩和）証明書（和文）¥15,000(税込)最短15分で結果お知らせ（NEAR法の場合）"
          />
          <ListItemBig
              iconColor="#f0862e"
              label="PDF版証明書をメール送付サービス（無料）"
          />
          <ListItemBig
              iconColor="#f0862e"
              label="QRコードつき証明書発行可（追加料金 ¥1,000 税込）"
          />
        </P>
      </ContentWrapper>
      <P css={`
            font-size: 1.175em;
            font-weight: 600;
            text-align: center;
          `}
        >
        検査をご希望の方は必ずご予約の上ご来院ください。<br /><br />
        <a href="../contact-reservation-pcr/"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}
          >
          検査予約
        </a>
        <a href="../contact-pcr/"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}
          >
          お問い合わせ
        </a>
      </P>
      <P css={`
            font-size: 1em;
            font-weight: 500;
            text-align: center;
          `}
        >

      </P>
      <P>
        <H4>PCR検査（海外渡航向け）</H4>
        渡航用新型コロナウイルスに関する検査証明書を作成いたします。<br />
        2020年11月のサービス開始から2,500件以上の検査・証明書を発行しています（2022年4月現在）。<br /><br />
        当院が対応している渡航国・地域例<br />
        <P>
        アメリカハワイ州、アメリカ本土、グアム、カナダ、香港、中国、台湾、韓国、ベトナム、シンガポール、タイ、カンボジア、ラオス、インドネシア、マレーシア、ブータン、インド、スリランカ、ネパール、バングラデシュ、フィリピン、フランス、イギリス、スイス、スペイン、ドイツ、スウェーデン、デンマーク、フィンランド、ポルトガル、オランダ、ベルギー、オーストリア、コソボ、ロシア、ラトビア、UAE、イスラエル、トルコ、ヨルダン、エジプト、エチオピア、ケニア、ザンビア、タンザニア、ジプチ、ガーナ、セネガル、カメルーン、モザンビーク、マダガスカル、ルワンダ、オーストラリア、ニュージーランド、パラオ、ブラジル、チリ、メキシコ、ペルー等
        </P>
        
        その他の国・地域対応の証明書も発行しております。詳細についてはお問い合わせください。<br />
        証明書は原本の発行に加え、電子版（PDF版）の対応も行っております。<br />

<br />

====出国前核酸増幅法（RT-PCR・NEAR）検査・定性抗原検査概要====<br />
〇検査法：①核酸増幅検査（NAAT）、リアルタイム方式RT-PCRまたはNEAR法<br />
②定性抗原検査<br />
〇採取方法：鼻咽頭スワブ、もしくは唾液（NEAR法と抗原検査はスワブ検体のみ）<br />
〇費用：<br />
（RT-PCR）15,000円（英文証明書・PDF版込、税込）<br />
（NEAR）15,000円（英文証明書・PDF版込、税込）<br />
（定性抗原）10,000円（英文証明書・PDF版込、税込）<br />
※ハワイ推奨証明書ご希望の場合、500円の追加料金がかかります。<br />
〇証明書発行時間について*<br />
RT-PCR検査証明書発行：<br />
09:00～11:15　→　当日17時発行<br />
11:30～16:15　→　当日21時発行<br />
16:30～19:00　→　翌日9時以降発行<br />
NEAR証明書発行**：<br />
    09:00～10:00　→　当日13時発行<br />
10:30～12:00　→　当日15時発行<br />
14:00～16:00　→　当日19時発行<br />
定性抗原検査**：<br />
09:00～16:00　→　当日4時間後発行<br />
16:30～19:00　→　翌朝09:30発行<br />
*日曜・祝日を除きます。再検査に回った場合などは上記の時間より遅くなる場合がございます。<br />
**NEAR法と抗原検査のみ証明書お急ぎ発行サービスご利用可（検査後およそ1時間で証明書発行 +3,000円）<br />
〇持ち物：パスポート原本<br />
<br />

====帰国/入国後待機期間短縮のための核酸増幅法（RT-PCR・NEAR）検査概要====<br />
〇検査法：核酸増幅検査（NAAT）、リアルタイム方式RT-PCRまたはNEAR法<br />
〇採取方法：鼻咽頭スワブ、もしくは唾液（NEAR法はスワブ検体のみ）<br />
〇費用：<br />
NEAR（当日証明書発行）15,000円（和文証明書・PDF版込、税込）<br />
RT-PCR（翌朝証明書発行）15,000円（和文証明書・PDF版込、税込）<br />
〇証明書発行時間について*<br />
　NEAR証明書発行**：<br />
    11:00～15:00　→　当日16時発行<br />
RT-PCR検査証明書発行：<br />
11:00～16:00　→　翌朝10時発行<br />
*日曜・祝日を除きます。再検査に回った場合などは上記の時間より遅くなる場合がございます。<br />
**NEAR法のみ証明書お急ぎ発行サービスご利用可（検査後およそ1時間で証明書発行 +3,000円）<br />
<br />

        <H4>PCR検査（海外渡航以外）</H4>
        「無症状」で、かつ、新型コロナウイルス陽性者と「濃厚接触歴がない」方を対象に、自費（保険適用外）でPCR検査を提供しております。<br />
        検査の種類・費用・受付時間の詳細については、<Link to="#fee" css={`font-size: 1.25em;`}>こちら</Link><br />
        ※保険や公費の検査は月水金土の時間限定で対応しております。予約については<Link to="https://reserva.be/hirahatacovidtest" css={`font-size: 1.25em;`}>こちら</Link><br /><br/>
        
        <ListItemBig
          iconColor="#f0862e"
          label="最短当日16時の証明書渡し"
          />
        <ListItemBig
          iconColor="#f0862e"
          label="陰性証明書の送付対応可能（メール、または郵送）"
          />
        <ListItemBig
          iconColor="#f0862e"
          label="陽性であった場合医師がお電話にて連絡の上、ご相談にのります。"
          />
        <br />
        持ち物：身分証明書（運転免許証、健康保険証、またはパスポート）<br />
        ＊お申込み後、看護師が事前確認のため、当院電話番号（03-3400-3288）からご連絡することがございます。ご了承ください。
      </P>
      
      <Anchor id="fee"></Anchor>      
      <H3>各種検査費用・受付時間</H3>
      
      <Flex
          wrap="true"
          justifyAround
          css={``}
      >
        <FlexItem
          css={`
            width: 300px;
            margin-bottom: 1em;
          `}
        >
      <Card>
        <Span>核酸増幅法（NAT/NAAT）</Span>
        <Span>Real-time RT-PCR</Span>        
        <H4p>鼻咽頭PCR</H4p>
        <P>
          鼻に綿棒を入れて検体を採取するPCR検査です。<br />
          世界各国で採用されている標準的な検査方法です。<br /><br />
          検査料金：<span css={`font-size: 1.25em;`}>15,000円</span><br />
          <span css={`margin-left: 1.5em;`}>　(証明書発行料・税込)</span><br />
        </P>
      </Card>
        </FlexItem>
        <FlexItem
          css={`
            width: 300px;
            margin-bottom: 1em;
          `}
        >
          <Card>
            <Span>核酸増幅法（NAT/NAAT）</Span>
            <Span>Real-time RT-PCR</Span>   
            <H4p>唾液PCR</H4p>
            <P>
              唾液を用いたPCR検査です。<br />
              <span css={`color: #ff0000;`}>唾液採取1時間前からの飲食・飴・ガム・歯磨き・うがいなどはお控えください。</span><br />
              <br />
              検査料金：<span css={`font-size: 1.25em;`}>15,000円</span><br />
              <span css={`margin-left: 1.5em;`}>　(証明書発行料・税込)</span><br />
            </P>
          </Card>
        </FlexItem>
        <FlexItem
          css={`
            width: 300px;
            margin-bottom: 1em;
          `}
        >
          <Card>
            <H4i>抗体検査</H4i>
            <Span>抗体検査のみは予約不要です</Span>
            <P>
              採血を行います。<br />
              過去の感染の有無や、ワクチン接種によって得られた抗体の量を調べることができます。<br /><br />
              検査料金：<br />
                IgG (N)：<span css={`font-size: 1.25em;`}>4,000円</span><br />
                IgG (S)：<span css={`font-size: 1.25em;`}>6,000円</span><br />
                IgM：<span css={`font-size: 1.25em;`}>5,000円</span><br />
              <Span css={`margin-left: 1.5em;`}>　(IgG/IgM共に税込)</Span><br />
            </P>
          </Card>
        </FlexItem>
        <FlexItem
          css={`
              width: 300px;
              margin-bottom: 1em;
            `}
          >
          <Card>
            <Span>Rapid Antigen Test</Span>
            <H4i>抗原検査</H4i>
            <P>
              鼻咽頭、または鼻腔スワブ検査です。<br />
              即日結果判定可能です。<br />
              ご希望の場合、PDF版メール送信も可能です。<br /><br />
              検査料金：<span css={`font-size: 1.25em;`}>10,000円</span><br />
              <Span>　(証明書発行料・税込)</Span><br />
            </P>
          </Card>
        </FlexItem>
        <FlexItem
          css={`
              width: 300px;
              margin-bottom: 1em;
            `}
          >
          <Card>
            <H4c>渡航用健康診断書<br />
            <Span>（Fit to Fly certiflcate, Recovery certiflcate,等)</Span></H4c>
            
            <P>
              英文で発行いたします。<br />
              証明書発行料：<br />
              <Span>肺レントゲン撮影を要する証明書：</Span><span css={`font-size: 1.25em;`}>¥7,000円（税込）</span><br />
              <Span>肺レントゲン撮影を要しない証明書：</Span><span css={`font-size: 1.25em;`}>¥5,000円（税込）</span>
              ※英文の場合 +3,000円かかります。<br />
            </P>
          </Card>
        </FlexItem>
      </Flex>
      
      <H4>受付時間</H4>
      <P>
        <ul>
          <li>月〜金　09:00~19:00（<span css={`font-weight: 600;`}>木曜日は17:00まで</span>）</li>
          <li>土　9:00〜13:00</li>
        </ul>
        <br />
      </P>
      
      <H4>陰性証明書について</H4>
      <P>
       <ul>
         <li>発行される時間：最短当日16時~（PDF版のみ）※迅速抗原検査の場合、4時間後</li>
         <li>原本お渡し対応時間：9:30～20:00（日・祝日を除く）</li>
         <li>対応言語：英語または日本語</li>
         <li>料金：検査料金に含まれています（米国ハワイ州渡航の場合、証明書用紙代が追加で500円かかります）</li>
         <li>
           抗体検査の証明書発行は原則行っておりません。結果通知書をお渡しいたします。<br />
           ただし、渡航国の条件に抗体検査が含まれる場合はこの限りではありません。
         </li>
       </ul>        
      </P>
     
      <ContentWrapper hasMarginBottom hasMarginTop>
          <Flex column>
            <P >ご来院前の注意事項</P>
            <ListItem
              iconColor="#f0862e"
              label="検査当日、必ずパスポート原本をお持ちください（陰性証明書へ申請者情報を転記いたします）"
              />
            <ListItem
              iconColor="#f0862e"
              label="風邪症状（熱、咳、鼻水など）や呼吸症状がある方のご来院はご遠慮ください。"
              />
            <ListItem
              iconColor="#f0862e"
              label="ご来院の際はマスクの着用をお願いいたします"
              />
            <ListItem
              iconColor="#f0862e"
              label="唾液PCR検査の場合、検査結果に影響を及ぼす可能性があるため、予約時間1時間前からのご飲食、歯磨き、うがいなどはお控えください。"
              />
          </Flex>
      </ContentWrapper>
      
      <P css={`
            font-size: 1.25em;
            font-weight: 600;
            text-align: center;
          `}
        >
        検査をご希望の方は必ずご予約の上ご来院ください。<br /><br />
        <a href="../contact-reservation-pcr/"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}
          >
          検査予約
        </a>
        <a href="../contact-pcr/"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}
          >
          お問い合わせ
        </a>
      </P>
      <P css={`
          font-size: 1em;
          font-weight: 500;
          text-align: center;
        `}
      >
      </P>
      <Anchor id="faq"></Anchor>
      <H3>よくあるご質問</H3>
      <ul>
        <li><Link to="#1">渡航用のPCR検査が必要ですが、こちらの検査で合っていますか？</Link></li>
        {/* <li><Link to="#2">PCR等の各検査や、陰性証明書の費用はいくらですか？</Link></li> */}
        <li><Link to="#3">渡航目的以外のPCR検査はできますか？</Link></li>
        <li><Link to="#4">来院時に持参する物や注意事項はありますか？</Link></li>
        <li><Link to="#5">検査はどれくらい時間かかりますか？</Link></li>
        {/* <li><Link to="#6">証明書はいつ受け取ることができますか？</Link></li> */}
        <li><Link to="#8">土日の実施可能ですか？</Link></li>
        {/* <li><Link to="#9">検査キットの精度は信頼できますか？どこで検査するのですか？</Link></li> */}
        <li><Link to="#10">万が一陽性だったときはどうすればよいですか？</Link></li>
        <li><Link to="#11">陰性証明書を申し込んで陽性だった場合、陽性証明書は出ますか？</Link></li>
        <li><Link to="#12">検査に年齢制限はありますか？</Link></li>
        <li><Link to="#13">陰性証明書はどのように渡されますか？</Link></li>
        <li><Link to="#14">PCR検査、抗体検査（IgM・IgG）は保険適応されますか？</Link></li>
        <li><Link to="#15">お支払い方法は？</Link></li>
        <li><Link to="#16">予約後のキャンセルはできますか？</Link></li>
        <li><Link to="#17">予約や問い合わせのメール後、全く返信が来ません</Link></li>
      </ul>
      <Card>
        <Anchor id="1"></Anchor>
        <H4>渡航用のPCR検査が必要ですが、こちらの検査で合っていますか？</H4>
        <P>
          はい。<br />
          当院は中国駐日大使館、米国ハワイ州保健局、経産省TECOT、アフリカCDCの渡航指定検査機関です。<br />
          中国と米国ハワイ州への渡航は、それぞれの政府指定の陰性証明書を発行いたします。<br />
          その他世界各国への新型コロナウイルス関連の検査証明書も対応しています。<br />
          なお、陰性証明の必要記載事項内容は、渡航先や搭乗の航空会社によって異なりますので、必ず事前に渡航先の大使館や航空会社にご確認のうえ、 <a href="https://www.hirahata-clinic.or.jp/contact-reservation-pcr/" css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`} >
          検査予約
          </a>より検査をお申し込みください。
        </P>
        <P>
          ＜当院が取得済みの認定＞
          <ul>
            <li><Link to="https://www.meti.go.jp/policy/investment/tecot/top.html" external >日本経産省TeCOTビジネス渡航者向け検査証明登録機関</Link></li>
            <li><Link to="http://www.china-embassy.or.jp/jpn/sgxw/t1828630.htm" external >中国駐日本大使館管轄地域内指定検査機関</Link></li>
            <li><Link to="https://www.allhawaii.jp/covid19/prepare/certificate/" external >米国ハワイ州保健局指定の日本検査機関</Link></li>
            <li><Link to="https://africacdc.org/trusted-travel/" external >アフリカCDC（Trusted Travels）登録機関</Link></li>
          </ul>
        </P>
      </Card>

      {/* <Card>
        <Anchor id="2"></Anchor>
        <H4>PCR等の各検査や、陰性証明書の費用はいくらですか？</H4>
        <P>
          費用については、以下のとおりです。証明書や消費税込みの価格となっております。
          <ul>
            <li>RT-PCR検査（鼻咽頭・唾液）：30,000円（英/和文証明書・PDF版・税込）</li>
            <li>中国渡航のみ検査セット価格　¥49,000（中国大使館指定陰性証明書・PDF版込、税込）</li>
            ※中国渡航7日前PCR検査・２日前PCR検査及びIgM抗体検査（ダブル検査）<br/>
            <li>抗体検査　IgM：5,000円、IgG（S）：6,000円、IgG（N）：4,000円</li>
            <li>抗原検査：1,5000円（英語または日本語の証明書発行料・税込）</li>
            <li>渡航用健康証明書：5,000円～（税込）</li>
            <li>ハワイ州渡航専用陰性証明用紙：500円（税込）　※ハワイ渡航者のみ</li>
          </ul>
        </P>
      </Card> */}

      <Card>
        <Anchor id="3"></Anchor>
        <H4>渡航目的以外のPCR検査はできますか？</H4>
        <P>
          はい。できます。<br />
          「無症状」であること、「濃厚接触者」ではない方であれば可能です。<br />
          <br />
           保険・公費でのPCR検査は毎週土曜17:00~21:00のみ対応しております。<br />
          発熱や自覚症状がある方は<Link to="https://reserva.be/hirahatacovidtest" css={`font-size: 1.25em;`}>こちら</Link>からご予約下さい。
        </P>
      </Card>
      <Card>
        <Anchor id="4"></Anchor>
        <H4>来院時に持参する物や注意事項はありますか？</H4>
        <P>
        <ul>
          <li>渡航目的の検査については、渡航用陰性証明に申請者情報を転記、登録するため、必ずパスポート原本を持参してください。</li>
          <li>唾液のPCR検査の場合は、検査に影響がでるため、採取１時間前からの飲食・のど飴・ガム・歯磨き・うがいなどは必ずお控えください。</li>
          <li>来院時に風邪症状（熱、咳、鼻水など）や呼吸器症状がある方は、来院をお控えください。</li>
        </ul>
        </P>
      </Card>
      <Card>
        <Anchor id="5"></Anchor>
        <H4>検査はどれくらい時間かかりますか？</H4>
        <P>
          院内の混雑状況により多少時間の前後がありますが、PCR検査（鼻咽頭ぬぐい液）の標準検査時間は10分～15分。PCR検査（唾液）の標準検査時間は15分～30分程度です。<br />
          なお唾液が出にくい場合や採取方法の指導が必要な場合などにより、さらに時間を所要する場合がございます。
        </P>
      </Card>

      {/* <Card>
        <Anchor id="6"></Anchor>
        <H4>証明書はいつ受け取ることができますか？</H4>
        <P>
          最短で当日の16時に発行します。（午前中の検査のみ）<br />
          午後に検査をした場合、検査日の翌朝10時に証明書を発行します。（例：月曜日午後検査、火曜日午前10時に証明書発行）<br />
          PCR検査の場合、最短で当日の17時に発行します。（午前中に検査をした場合のみ）<br />
          午後に検査をした場合、検査日の翌朝10時に証明書を発行します。（例：月曜午後検査の場合、火曜午前10時証明書発行）。<br />
          なお、中国大使館指定の陰性証明書をご希望の場合、翌日12時発行です。<br />
          <b>日・祝日は休診ですので証明書原本のお渡しは対応していませんが、PDF版証明書をご指定のメールアドレスに送信することは可能です。</b><br />
          なお、再検査を要するなどの特別な事情が発生する場合は、結果が遅く出る場合がございます。<br />
          検査機関から情報が入り次第、直ちに状況をご連絡します。
        </P>
      </Card> */}
      
      <Card>
        <Anchor id="8"></Anchor>
        <H4>土日の実施可能ですか？</H4>
        <P>
          祝日ではない土曜日の9:00～13:00の検査を実施しております。<br />
        </P>
      </Card>

      {/* <Card>
        <Anchor id="9"></Anchor>
        <H4>検査キットの精度は信頼できますか？どこで検査するのですか？</H4>
        <P>
          厚生労働省認定、米国 FDA EUA（緊急使用承認）の検査キットを使用しています。検査の解析は、国内大手臨床検査機構で行われています。<br />
          ※なお、「偽陰性」（感染をしているのに陰性結果が出る）が発生する場合もございます。当院の検査に限られたことではなく、PCR検査にも限界があるということはご了承ください。
        </P>
      </Card> */}

      <Card>
        <Anchor id="10"></Anchor>
        <H4>万が一陽性だったときはどうすればよいですか？</H4>
        <P>
          陽性の方には、クリニックの医師より直接ご本人にお電話でご連絡を差し上げます。<br />
          クリニックより保健所に発生届を提出しますので、ご本人あるいは法人・団体様の担当の方に行っていただく作業はございません。<br />
          保健所から連絡が来るまでは自宅にて待機をお願いいたします。
        </P>
      </Card>
      <Card>
        <Anchor id="11"></Anchor>
        <H4>陰性証明書を申し込んで陽性だった場合、陽性証明書は出ますか？</H4>
        <P>
          陽性の方には陽性証明書の発行を行っております。<br />
          また、当院よりお客様へご連絡をいたします。
        </P>
      </Card>
      <Card>
        <Anchor id="12"></Anchor>
        <H4>検査に年齢制限はありますか？</H4>
        <P>
          PCR検査の場合、ご両親のサポートのもと2歳以上から、抗体検査を伴う場合は小学校高学年以上であれば対応いたします。
        </P>
      </Card>
      <Card>
        <Anchor id="13"></Anchor>
        <H4>陰性証明書はどのように渡されますか？</H4>
        <P>
          当院受付にて原本をお手渡しするほか、PDF版の証明書を要する場合には、ご要望いただければ併せて発行いたしますのでお気軽にお申し付けください。
        </P>
      </Card>
      <Card>
        <Anchor id="14"></Anchor>
        <H4>PCR検査、抗原検査、抗体検査は保険適応されますか？</H4>
        <P>
          発熱やその他の症状がある方は保険・公費対象となります。その他の目的（渡航前の検査や無症状の方）には保険は適用できず、自費となります。<br />
          また、抗体検査についてはすべて自費となります。
        </P>
      </Card>
      <Card>
        <Anchor id="15"></Anchor>
        <H4>お支払い方法は？</H4>
        <P>
          検査当日、受付にて現金またはクレジットカードのお支払いをお願い致します。<br />
        対応可能クレジットカード：<br />
          <Img src={card} css={`width:300px;`} /><br />
          電子マネーには対応しておりませんので、ご了承ください。
        </P>
      </Card>
      <Card>
        <Anchor id="16"></Anchor>
        <H4>予約後のキャンセルはできますか？</H4>
        <P>
          できます。<br />
          キャンセル料は基本的に頂いておりませんが予約前日までにはご連絡をお願いいたします。
        </P>
      </Card>
      <Card>
        <Anchor id="17"></Anchor>
        <H4>予約や問い合わせのメール後、全く返信が来ません</H4>
        <P>
          当院PCR検査サービスでは、診療時間内のご予約メールやお問い合わせへの返答メールは基本的に当日（日・祝の場合はその翌日）なるべく早急に送信いたしております。<br /><br />

          もし24時間以内にPCR検査のお問い合わせ・お申込みについて当院から返信が届かない場合は、お手数ですが以下の点をご確認ください。<br />
          ◆迷惑メール防止機能により、当院からのメールが迷惑メールと認識され、受信画面に出てこない場合があります。<br />
          ◆迷惑メールフォルダやゴミ箱に自動的に振り分けられている可能性もありますので、一度ご確認いただきますようお願い致します。<br />
        </P>
      </Card>
    </SubLayout>
  );
};

export default Exams;
