import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStethoscope, faLeaf, faSyringe } from '@fortawesome/free-solid-svg-icons';

import Link from './Link';

import pancIcon from '../assets/img/panc-icon.svg';
import meIcon from '../assets/img/me-icon.svg';
import resIcon from '../assets/img/res-icon.svg';
import calIcon from '../assets/img/cal-icon.svg';
import endoIcon from '../assets/img/endo-icon.svg';
import onlineIcon from '../assets/img/online.svg';
import covidIcon from '../assets/img/covid-icon_menu.svg';

const Title = styled.span`
  font-size: ${({ size }) => (size !== 'sm' && 18) || 10}px;
  color: #666666;
  font-weight: 500;
`;

const Item = ({ icon, title, link, size, columnRight }) => (
  <Link to={link}>
    <Flex
      center={size !== 'sm'}
      css={`
        width: ${(size === 'sm' && ((columnRight && 100) || 100)) || 250}px;
        height: 60px;
        margin: ${(size === 'sm' && '0') || '10px'};
        border: ${(size !== 'sm' && 'solid 1px #707070') || 2};
        border-radius: 6px;
        background-color: #ffffff;
        padding-left: ${(columnRight && '30px') || 0};
      `}
    >
      <Flex center>{icon}</Flex>
      <Flex center>
        <Title size={size}>{title}</Title>
      </Flex>
    </Flex>
  </Link>
);

const Line = ({ leftTitle, leftLink, centerTitle, centerLink, rightTitle, rightLink, size }) => (
  <Flex justifyCenter>
    <FlexItem>
      <Item title={leftTitle} link={leftLink} size={size} />
    </FlexItem>
    <FlexItem>
      <Item title={centerTitle} link={centerLink} size={size} />
    </FlexItem>
    <FlexItem>
      <Item title={rightTitle} link={rightLink} size={size} />
    </FlexItem>
  </Flex>
);

const PcrMenu = ({ size }) => (
  <div>
    <Line
      size={size}
      leftTitle="検査予約"
      leftLink="/contact-reservation-pcr/"
      centerTitle="検査料金・受付時間"
      centerLink="/covid-pcr/#fee"
      rightTitle="よくあるご質問"
      rightLink="/covid-pcr/#faq"
    />
  </div>
);

export default PcrMenu;
