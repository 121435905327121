import React from 'react';
import styled from 'styled-components';
import Flex from 'styled-flex-component';

const Wrapper = styled(Flex)`
  width: ${({ width }) => width};
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
`;

const Inner = styled(Flex)`
  margin: 20px;
  width: ${({ width }) => width};
`;

const Card = ({ children, width = 'inherit' }) => (
  <Wrapper center width={width}>
    <Inner width={width} center column>
      {children}
    </Inner>
  </Wrapper>
);

export default Card;
